export const YearlyReturns = [
  {
    text: "FY 2024-25",
    labels: [
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
    ],
    nifty: [3.66, 0.51, 6.9, 4.3],
    auxo: [10.81, 16.1, -1.37, 5.33],
  },
];

export const MonthlyReturns = [
  {
    text: "FY 2024-25 Q1",
    labels: ["April", "May", "June"],
    nifty: [3.66, 0.51, 6.9],
    auxo: [10.81, 16.1, -1.37],
  },
  {
    text: "FY 2024-25 Q2",
    labels: ["July"], //["July", "August", "September"],
    nifty: [4.3],
    auxo: [5.33],
  },
  //   {
  //     text: "FY 2024-25 Q3",
  //     labels: ["October", "November", "December"],
  //     nifty: [3.66, 0.51, 6.9],
  //     auxo: [10.81, 16.1, -1.37],
  //   },
  //   {
  //     text: "FY 2024-25 Q4",
  //     labels: ["January", "February", "March"],
  //     nifty: [3.66, 0.51, 6.9],
  //     auxo: [10.81, 16.1, -1.37],
  //   },
];
