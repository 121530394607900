import img_hero from "./../assets/hero.jpg";
import img_1 from "./../assets/1.jpg";
import img_2 from "./../assets/2.jpg";
import React, { memo, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const LandingPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="theme-bg-color text-light">
      <div className="padding-top-100 pb-5 container" id="home">
        <div className="col-12 col-lg-10 offset-lg-1 py-4">
          <div className="row align-items-center">
            <div
              className="col-12 col-lg-7 order-2 order-lg-1 text-center text-lg-start"
              data-aos="fade-up"
            >
              <h1 className="display-2 fw-bold">"Grow your wealth with Us"</h1>
              <p className="fs-5 mt-4">
                It's not whether you're right or wrong that's important, but how
                much money you make when you're right and how much you lose when
                you're wrong.
              </p>
            </div>
            <div
              className="col-12 col-lg-5 order-1 order-lg-2 my-lg-0 px-4"
              data-aos="zoom-in"
            >
              <img
                src={img_hero}
                alt="loading..."
                className="img-fluid hero-img-two w-100 shadow rounded my-4"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container padding-top-100" id="philosophy">
        <div className="col-12 col-lg-10 offset-lg-1">
          <p
            className="display-5 fw-bold mb-4 text-center text-lg-start"
            data-aos="fade-up"
          >
            Our Investment Philosophy
          </p>
          <p className="fs-5 mb-5 text-center text-lg-start" data-aos="fade-up">
            At the core of Auxo's investment philosophy is a balanced approach
            that integrates both technical and fundamental analysis. This dual
            methodology allows for a comprehensive understanding of market
            dynamics, ensuring informed and strategic investment decisions.
          </p>
          <div className="mb-5 pb-4 px-4 px-lg-0">
            <div
              className="row bg-white text-dark rounded shadow-sm align-items-center"
              data-aos="fade-up"
            >
              <div className="col-12 col-lg-5 my-4">
                <img
                  src={img_2}
                  alt="loading..."
                  className="hero-img-two w-100"
                />
              </div>
              <div className="px-lg-5 col-12 col-lg-7 my-4">
                <p className="fs-2 fw-bold text-center text-lg-start">
                  Technical Analysis
                </p>
                <p className="fs-5 text-center text-lg-start text-secondary">
                  We delve into price patterns, trends, and market statistics to
                  forecast future movements. By examining charts, utilizing
                  indicators, and recognizing market cycles, We aim to identify
                  optimal entry and exit points. This approach helps in
                  capturing short-term opportunities and managing risks
                  effectively.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-5 px-4 px-lg-0">
            <div
              className="row bg-white text-dark rounded shadow-sm align-items-center"
              data-aos="fade-up"
            >
              <div className="px-lg-5 col-12 col-lg-7 my-4">
                <p className="fs-2 fw-bold text-center text-lg-start">
                  Fundamental Analysis
                </p>
                <p className="fs-5 text-center text-lg-start text-secondary">
                  We scrutinize the intrinsic value of assets by evaluating
                  financial statements, industry conditions, economic factors,
                  and company management. This long-term perspective helps in
                  identifying undervalued investments with strong growth
                  potential and solid financial health.
                </p>
              </div>
              <div className="col-12 col-lg-5 my-4">
                <img
                  src={img_1}
                  alt="loading..."
                  className="hero-img-two w-100"
                />
              </div>
            </div>
          </div>

          <div className="pb-5">
            <p className="fs-5 px-4 px-lg-0" data-aos="fade-up">
              Combining these two methods provides a robust framework for
              navigating the complexities of the financial markets. It ensures
              that Auxo's investment decisions are not only data-driven but also
              grounded in a deep understanding of the underlying assets. This
              philosophy aims to achieve consistent, sustainable growth while
              mitigating risks in an ever-evolving market landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LandingPage);
