import React, { memo, useEffect } from "react";
import img_founder from "./../assets/founder.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="theme-bg-color py-5">
      <div className="container">
        <div
          className="col-12 col-lg-10 offset-lg-1 theme-bg-secondary p-5 rounded shadow"
          data-aos="fade-up"
        >
          <p className="col-12 display-5 fw-bold mb-4">About Us</p>
          <div className="row">
            <div className="col-12 col-lg-8 order-1 order-lg-1">
              <p>
                Auxo Capital, led by Siddhant Sharma, a dedicated and
                knowledgeable financial expert. Siddhant's passion for financial
                markets ignited during his Class 12 studies and has grown
                steadily over the past seven years. With a deep-seated interest
                in finance, Siddhant has honed his skills and expertise to
                provide top-notch financial guidance.
              </p>
              <p>
                Siddhant Sharma is CFA Level 2 Cleared and holds a Bachelor of
                Commerce degree from Motilal Nehru College (eve), University of
                Delhi. His professional journey includes a notable role as a
                Financial Analyst at Daloopa, where he excelled in building
                sophisticated financial models for investment professionals. His
                ability to work collaboratively with a team of experts, coupled
                with his proficiency in data extraction and financial reporting,
                has earned him a reputation for delivering high-quality
                financial insights.
              </p>
              <p className="d-none d-xl-block">
                In addition to his professional experience, Siddhant has
                completed an internship at ValueSearch as an Equity Research
                Intern. During this time, he created comprehensive industry
                reports and research documents, further deepening his
                understanding of various sectors, including the Banking,
                Financial Services, and Insurance sector.
              </p>
            </div>
            <div className="col-12 col-lg-4 order-3 order-lg-2">
              <div className="d-flex flex-column align-items-center justify-content-center mb-2 rounded">
                <div className="px-4">
                  <img
                    src={img_founder}
                    alt="Siddhant Sharma"
                    className="bg-white member-photo"
                  />
                </div>

                <p className="fs-2 fw-bold m-0 mt-4 text-center">
                  Siddhant Sharma
                </p>
              </div>
            </div>

            <div className="order-2 col-12">
              <p className="d-xl-none">
                In addition to his professional experience, Siddhant has
                completed an internship at ValueSearch as an Equity Research
                Intern. During this time, he created comprehensive industry
                reports and research documents, further deepening his
                understanding of various sectors, including the Banking,
                Financial Services, and Insurance sector.
              </p>
              <p>
                Siddhant's commitment to continuous learning is evident through
                his certifications in the Global Financial Market Professional
                (GFMP) program from Bombay Stock Exchange(BSE) Institute Ltd.
              </p>
              <p>
                Through his analytical acumen and extensive market knowledge,
                Siddhant Sharma is dedicated to helping people achieve their
                financial goals. Whether you seek to make informed investment
                decisions, understand market trends, or develop a robust
                financial strategy, Siddhant's expertise is your trusted guide
                on the path to financial success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(About);
